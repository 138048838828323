/* eslint-disable */
import instance from "@/services/instance.js";

const MostCompareCity = payload => {
  return instance.instanceToken
    .get("/analytics/MostCompareCity", {params:payload})
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const MostCompareCountry = payload => {
  return instance.instanceToken
    .get("/analytics/MostCompareCountry",{params:payload})
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const MostSearchedCity = payload => {
  return instance.instanceToken
    .get("/analytics/MostSearchedCity", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const MostSearchedCountry = payload => {
  return instance.instanceToken
    .get("/analytics/MostSearchedCountry", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const mostClickCity = payload => {
  return instance.instanceToken
    .get("/analytics/mostClickCity", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const mostClickCountry = payload => {
  return instance.instanceToken
    .get("/analytics/mostClickCountry", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const getCount = payload => {
  return instance.instanceToken
    .get("/analytics/getCount", {params:payload})
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const getRFPCount = payload => {
  return instance.instanceToken
    .get("/analytics/getRFPCount", {params:payload})
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const getMostClickedJSON = payload => {
  return instance.instanceToken
    .get("/analytics/mostClickCity/v2", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const getMostClickedCountryJSON = payload => {
  return instance.instanceToken
    .get("/analytics/mostClickCountry/v2", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const getMostSearchedJSON = payload => {
  return instance.instanceToken
    .get("/analytics/MostSearchedCity/v2", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const getMostSearchedCountries = payload => {
  return instance.instanceToken
    .get("/analytics/MostSearchedCountry/v2", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const getMostComparedJSON = payload => {
  return instance.instanceToken
    .get("/analytics/MostCompareCity/v2", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const getMostComparedCountryJSON = payload => {
  return instance.instanceToken
    .get("/analytics/MostCompareCountry/v2", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

export default {
  MostCompareCity: MostCompareCity,
  mostSearchedCity: MostSearchedCity,
  mostClickCity: mostClickCity,
  getCount: getCount,
  getRFPCount: getRFPCount,
  GetMostClickedCountryJSON: getMostClickedCountryJSON,
  GetMostClickedJSON: getMostClickedJSON,
  GetMostComparedCountryJSON: getMostComparedCountryJSON,
  GetMostComparedJSON: getMostComparedJSON,
  GetMostSearchedCities: getMostSearchedJSON,
  GetMostSearchedCountries: getMostSearchedCountries,
  GetMostClickedCountries:mostClickCountry,
  MostCompareCountry:MostCompareCountry,
  MostSearchedCountry:MostSearchedCountry
};
